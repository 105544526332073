<template>
  <div v-if="originalGoalData.title" class="bg-white rounded-5 px-50 py-30 w-full h-full">
    <GoalForm
      :goal="goalForm"
      mode="edit"
      :original-goal-data="originalGoalData"
      :categories-list="categoriesList"
      @submit="onSubmit"
    />
  </div>

  <!-- U N S A V E D   C H A N G E S   H A N D L E R  -->
  <UnsavedChangesModal v-if="goalForm.category" :disabled="disabledHandleChanges" :data="goalForm" />
</template>

<script lang="ts">
  import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue';
  import { useToast } from "vue-toastification";
  import { useRoute } from 'vue-router';

  import GoalForm from "@/views/admin/goals/components/GoalForm.vue";
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IPredefinedGoalForm, ICategory, IPredefinedGoalResponse, IGoal } from '@/types';
  import { vuex } from "@/store";
  import { router, routesNames } from '@/router';
  import { myGoalsService, categoriesService } from "@/services";
  import { PREDEFINED_GOAL_PARAMS } from '@/views/admin/goals/api-params';
  import { CATEGORIES } from '@/views/admin/activities/api-params';

  export default defineComponent({
    name: 'UpdateGoal',

    components: { GoalForm, UnsavedChangesModal },

    setup() {
      const toast = useToast();
      const route = useRoute();
      const state = reactive({
        categoriesList: [] as ICategory[],
        goalForm: {} as IPredefinedGoalForm,
        originalGoalData: {} as IPredefinedGoalForm
      });
      const goalId = ref<null | string>(null);

      const disabledHandleChanges = ref<boolean>(false);

      const { categoriesList, goalForm, originalGoalData } = toRefs(state);

      function checkGoalId() {
        const { query } = route;

        if (!query.id) {
          router.push({ name: routesNames.root });
        } else {
          goalId.value = query.id as string;
        }
      }

      async function onSubmit(payload: IPredefinedGoalResponse) {
        vuex.setLoading(true);

        await myGoalsService.updatePredefinedGoal(goalId.value as string, payload)
          .then(() => {
            toast.success('Goal has been updated');
            disabledHandleChanges.value = true;
            router.push({ name: routesNames.adminGoals });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      function setGoalForm(goal: IGoal) {
        const form: IPredefinedGoalForm = {
          title: goal.title,
          category: goal.category as ICategory,
          tasks: goal.tasks 
          ? goal.tasks.map((task: any) => {
            return {
              id: task.id,
              title: task.title,
              description: task.description || '',
            };
          }) 
          : []
        };

        goalForm.value = { ...form };
        originalGoalData.value = { ...form };
      }

      async function fetchInitData() {
        vuex.setLoading(true);

        await Promise.all([
          categoriesService.fetchCategories(CATEGORIES),
          myGoalsService.fetchGoal(goalId.value as string, PREDEFINED_GOAL_PARAMS)
        ])
          .then(([categories, goal]) => {
            categoriesList.value = categories.data;
            setGoalForm(goal);
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(() => {
        checkGoalId();
        fetchInitData();
      });

      return {
        categoriesList,
        goalForm,
        goalId,
        originalGoalData,
        disabledHandleChanges,

        onSubmit
      };
    }

  });
</script>